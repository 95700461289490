import React, {Component} from 'react'
import Layout from 'components/Layout/Layout'
import {navigate, Link, StaticQuery, graphql} from 'gatsby'
import SEO from "utils/Seo"
import styled from "styled-components";
import BgImage from '../components/shared/BgImage'
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const IframeWrapper = styled.div`
  height: 100vh;
  position: relative;
  width: 100%;

  h1 {
    visibility: hidden;
  }

`

const BottomOfIframe = styled.div`
  position: absolute;
  top: 75%;

  @media (min-width: 992px) {
    top: 100%;
  }
`

const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 642px;
  position: relative;
  z-index: 2;
  color: ${props => props.theme.colors.white};
  text-align: center;
  padding: 0 0 0 1rem;
  margin: 0 auto;

  .scroll-helper {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */

  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1.25rem;
    font-family: 'Proxima Nova W05 Bold', Arial, sans-serif;
    font-weight: bold;
  }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                FallbackXs: file(relativePath: { eq: "575x960-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                FallbackSm: file(relativePath: { eq: "991x1320-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                FallbackLg: file(relativePath: { eq: "1200x720-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                FallbackXl: file(relativePath: { eq: "1920x1080-min.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                logo: file(relativePath: { eq: "Logo_lrg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 800, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <ThankYou props={props} data={data}/>
        )}
    />
)

class ThankYou extends Component {
    render() {
        return (
            <Layout pageName="thank-you">
                <SEO slug="thank-you" title="Thank you"/>
                <IframeWrapper>
                    <h1 className="sr-only">FROZEN Education</h1>
                    <BgImage
                        data={this.props.data}
                    />
                    <BottomOfIframe id="bottomOfIframe"/>
                    <Content>
                        <div className="scroll-helper">
                            <div className="logo">
                                <GatsbyImage placeholder="none"
                                             image={getImage(this.props.data.logo)}
                                             alt=""
                                             title=""
                                             className="d-block img-fluid"
                                />
                            </div>
                            <h2>Your form was successfully submitted!</h2>
                            <div className="d-block d-sm-flex" style={{
                                paddingTop: '2rem',
                                paddingBottom: '4rem'
                            }}>
                                <Link to="/" className="btn btn--new my-2 my-sm-0 mx-auto"><span>Back to Home</span></Link>
                            </div>
                        </div>
                    </Content>
                </IframeWrapper>
            </Layout>
        )
    }

}

export default Query
